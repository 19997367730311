export enum LangEnum {
	ES = 1,
	EN = 2
}

export enum LANG {
	ES = 'es',
	EN = 'en'
}

export type LangActionType = LangEnum.ES | LangEnum.EN;
