import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingService } from '@services/loading.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
	selector: 'vass-loading',
	templateUrl: './loading.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, AsyncPipe, ProgressSpinnerModule],
})
export class LoadingComponent {
	isLoading$;
	constructor(private readonly loadingService: LoadingService) {
		this.isLoading$ = this.loadingService.showLoadingSource;
	}
}
