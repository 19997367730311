import { createReducer, on } from '@ngrx/store';
import { Usuario } from '@core/models/user.interface';
import { setUser, unSetUser } from './auth.actions';
export interface State {
	user: Usuario;
}

export const initialState: State = {
	user: {
		id: '',
		username: '',
		name: '',
		email: '',
		token: '',
		avatar: '',
		rol: '',
		status: ''
	}
};

const _authReducer = createReducer(
	initialState,
	on(setUser, (state, { user }) => ({ ...state, user: { ...user } })),
	on(unSetUser, (state) => ({ ...state, user: initialState.user }))
);

export function authReducer(state: any, action: any) {
	return _authReducer(state, action);
}
