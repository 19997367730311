export const environment = {
	production: false,
	TOKEN_URL: 'e35d51b9c6e9fd076963d5bf7b980cbd4db8721fbbe3791cf8649ee5d111d2a0',
	api: 'https://api-appcrypto-qa.towerbank.com',
	keyEncrypt: '2b7e151628aed2a6abf7158809cf4f2c',
	flowIdForeigner: '65b2ba2c47e9ad001c62a395',
	flowIdResident: '65b2b92fb542e6001c0e67a5',
	flowIdSelfie: '65d4e825cc41e4001cd4fe88',
};

console.log('QA environment loaded');