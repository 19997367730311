import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private pendingLoadings = 0;
	public showLoadingSource = new BehaviorSubject<boolean>(false);

	

	/**
	 * The function increments the pendingLoadings variable, and then emits a true value to the
	 * showLoadingSource observable
	 */
	show() {
		this.pendingLoadings++;
		this.showLoadingSource.next(true);
	}

	/**
	 * If the number of pending loadings is less than or equal to zero, then we hide the loading
	 * indicator
	 */
	hide() {
		this.pendingLoadings--;

		if (this.pendingLoadings <= 0) {
			this.showLoadingSource.next(false);
		}
	}
}
