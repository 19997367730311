import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandleService } from '@app/core/services/error-handle.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

@Component({
	selector: 'vass-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	standalone: true,
	imports: [DialogModule, TranslateModule, CommonModule],
})
export class ModalComponent implements OnInit {

	public router = inject(Router);

	public message$;
	public error_code$;
	@Input()
	public buttonLabel: string = '';

	constructor(public errorHandleService: ErrorHandleService) {}
	@Output()
	ngOnInit() {
		this.message$ = this.errorHandleService.message;
		this.errorHandleService.error_code.subscribe(err => {
			this.error_code$ = err;
			//console.log('✅ error: ' + this.error_code$);
		});
	}

	onContinueClicked() {
		this.errorHandleService.hide();
	}
}
