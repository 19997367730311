/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { HttpService } from "./core/services/http.service";

import { LANG } from "@core/models/lang.enum";
import { TranslateService } from "@ngx-translate/core";

import { NgxPermissionsService } from "ngx-permissions";

import { AppState } from "@core/redux/app.reducer";
import { setRoute } from "@core/redux/router/router.actions";
import { select, Store } from "@ngrx/store";

import { CommonModule, ViewportScroller } from "@angular/common";
import { LoadingComponent } from "./core/components/loading/loading.component";
import { ModalComponent } from "./core/components/modal/modal.component";
import { ErrorHandleService } from "./core/services/error-handle.service";
import { GetTokenInitService } from "./core/services/secureServices/get-token-init.service";
import { ScrollToTopOnNavigateDirective } from "src/shared/directives/scroll-to-top-on-navigate.directive";

@Component({
  selector: "vass-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [
    LoadingComponent,
    RouterOutlet,
    RouterModule,
    ModalComponent,
    CommonModule,
    ScrollToTopOnNavigateDirective
  ],
  providers: [GetTokenInitService],
})
export class AppComponent implements OnInit, AfterViewChecked {
  loader$: Observable<boolean>;
  browserLang: string | undefined;
  isViewModal$: any;

  /**
   * Creates an instance of app component.
   *
   * @param cdRef
   * @param translate
   * @param router
   * @param store
   * @param permissionsService
   */
  constructor(
    private cdRef: ChangeDetectorRef,

    public translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private permissionsService: NgxPermissionsService,
    private getTokenService: GetTokenInitService,
    private viewportScroller: ViewportScroller,
    public errorHandleService: ErrorHandleService
  ) {
    translate.addLangs([LANG.ES, LANG.EN]);
    translate.setDefaultLang(LANG.ES);

    this.browserLang = translate.getBrowserLang();
    if (this.browserLang) {
      translate.use(
        this.browserLang.match(/es|en/) ? this.browserLang : LANG.ES
      );
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  /**
   * AfterViewChecked
   * Método del ciclo de vida.
   */
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  /**
   * OnInit
   * Método del ciclo de vida donde seteamos el usuario de cas y observamos el cambio de ruta de la aplicaion.
   */
  ngOnInit() {    
    //TODO: remove this ugly code. Only for get token informed in query params before redirect to welcome page
    const data = window.location.href.split("token=")[1]?.split("&")[0];
    if (data) {
      localStorage.setItem("token", data);
    }
    const perm = ["ADMIN"];
    this.permissionsService.loadPermissions(perm);

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe({
        next: (e: any) => {
          const url = e.urlAfterRedirects;
          this.store.dispatch(setRoute({ current: url }));
        },
      });

    // this.getAccesToken();
    this.getTokenService.getInitTransaction().subscribe();
    this.loader$ = this.store.pipe(select((store: any) => store.ui.isLoading));
    this.isViewModal$ = this.errorHandleService.isViewModal;
  }

  // private getAccesToken(): void {
  //   this.getTokenService
  //     .getInitTransaction()
  //     .pipe()
  //     .subscribe((resp) => {
  //       console.log("Resp App Token", resp);
  //       // this.getTokenService.setAccesToken = resp["Access-Token"];
  //     });
  // }
}
