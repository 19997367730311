import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[scrollToTopOnNavigate]",
  standalone: true,
})
export class ScrollToTopOnNavigateDirective {

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  private scrollToTop(): void {
    if (this.el.nativeElement) {
      this.el.nativeElement.scrollTop = 0;
    }
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }
}
