import { createReducer, on } from '@ngrx/store';
import { setRoute } from './router.actions';

export interface State {
	current: string;
}

export const initialState: State = {
	current: ''
};

const _routerReducer = createReducer(
	initialState,

	on(setRoute, (state, { current }) => ({ ...state, current }))
);

export function routerReducer(state: any, action: any) {
	return _routerReducer(state, action);
}
