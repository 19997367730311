import { environment } from "./environment";

export const apiUrls = {
	metamap: 			`${environment.api}/Client/verifications`,
	clientProfile: 		`${environment.api}/Client/profile`,
	getProfile: 		`${environment.api}/Client/profile`,
	updateProfile: 		`${environment.api}/Client`,
	address: 			`${environment.api}/Client/address`,
	codeOtp: 			`${environment.api}/Client/otp-1`,
	verifyOtp: 			`${environment.api}/Client/otp-2`,
	financieInfo: 		`${environment.api}/Client/finance-info`,
	cryptoInfo: 		`${environment.api}/Client/crypto-info`,
	onboarding: 		`${environment.api}/Client/onboarding`,
	clientReview: 		`${environment.api}/Client/client-review`,
	screener: 			`${environment.api}/Client/client-screener`,
	documents: 			`${environment.api}/Document/upload-document`,
	deleteDocuments: 	`${environment.api}/Document/document`,
	cardInfo: 			`${environment.api}/Client/card-info`,
	getAccount:  		`${environment.api}/Client/account`,
	validateUser: 		`${environment.api}/Client/framework/cryptocustom/validateuserdata`,
	enrollUser: 		`${environment.api}/Client/UserEnroll`,
	accessToken: 		`${environment.api}/onboarding-web/security/auth`,
	appianProcess:      `${environment.api}/security/appian-process`,
};