import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandleService {
	public isViewModal = new BehaviorSubject<boolean>(false);
	public message = new BehaviorSubject<string>('');
	public error_code = new BehaviorSubject<string>('');

	

	show(message, error_code?: string) {
		this.isViewModal.next(true);
		this.message.next(message);
		this.error_code.next(error_code);
	}

	hide() {
		this.isViewModal.next(false);
	}
}
