/* eslint-disable complexity */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { ErrorHandleService } from "../services/error-handle.service";
@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(public errorHandle: ErrorHandleService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err) {
          switch (err.status) {
            case 400:
              this.errorHandle.show("MODAL_LABELS.ERROR_400");
              break;
            case 401:
              this.errorHandle.show("MODAL_LABELS.ERROR_401");
              break;
            case 403:
              this.errorHandle.show("MODAL_LABELS.ERROR_403");
              break;
            case 404:
              this.errorHandle.show("MODAL_LABELS.ERROR_400");
              break;
            default:
              this.errorHandle.show("MODAL_LABELS.ERROR_400");
              break;
          }
          return throwError(() => err);
        }
      })
    );
  }
}
