import { ExtraOptions, PreloadAllModules, Routes } from '@angular/router';
import { ROUTES } from './pages/routes';

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

export const APP_ROUTES: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: ROUTES.WELCOME,
	},
	{
		path: ROUTES.WELCOME,
		loadComponent: () => import('./pages/welcome/welcome.component').then((m) => m.WelcomeComponent),
		//loadComponent: () => import('./pages/crypto-activities/crypto-activities.component').then((m) => m.CryptoActivitiesComponent),

	},
	{
		path: ROUTES.OTP,
		loadComponent: () => import('./pages/send-otp/send-otp.component').then((m) => m.SendOtpComponent),
	},
	{
		path: ROUTES.VERIFIDATA,
		loadComponent: () => import('./pages/verification-data/verification-data.component').then((m) => m.VerificationDataComponent),
	},
	{
		path: ROUTES.EMPLOYMENT,
		loadComponent: () => import('./pages/employment-and-address/employment-and-address.component').then((m) => m.EmploymentAndAddressComponent),
	},
	{
		path: ROUTES.VERIFY,
		loadComponent: () => import('./pages/verify-identity/verify-identity.component').then((m) => m.VerifyIdentityComponent),
	},
	{
		path: ROUTES.FINANCIAL,
		loadComponent: () => import('./pages/financial-data/financial-data.component').then((m) => m.FinancialDataComponent),
	},
	{
		path: ROUTES.DOCUMENTS,
		loadComponent: () => import('./pages/loading-documents/loading-documents.component').then((m) => m.LoadingDocumentsComponent),
	},
	{
		path: ROUTES.CRYPTO_INFO,
		loadComponent: () => import('./pages/crypto-activities/crypto-activities.component').then((m) => m.CryptoActivitiesComponent),
	},
	{
		path: ROUTES.TERMS,
		loadComponent: () => import('./pages/terms/terms.component').then((m) => m.TermsComponent),
	},
	{
		path: ROUTES.CONTACT,
		loadComponent: () => import('./pages/costumer/costumer-contact/costumer-contact.component').then((m) => m.CostumerContactComponent),
	},
	{
		path: ROUTES.FINISHED,
		loadComponent: () => import('./pages/costumer/costumer-finished/costumer-finished.component').then((m) => m.CostumerFinishedComponent),
	},
	{
		path: ROUTES.DEBIT_CARD,
		loadComponent: () => import('./pages/debit-card/debit-card.component').then((m) => m.DebitCardComponent),
	},
	{
		path: ROUTES.CONGRATS,
		loadComponent: () => import('./pages/debit-card/congrats/congrats.component').then((m) => m.CongratsComponent),
	},
	{
		path: ROUTES.DOWNLOAD_APP,
		loadComponent: () => import('./pages/verification-data/download-screen/download-screen/download-screen.component').then((m) => m.DownloadScreenComponent),
	},
	{
		path: ROUTES.NOT_ALLOWED,
		loadComponent: () => import('./pages/error/not-allowed/not-allowed.component').then( (m) => m.NotAllowedComponent)
	}
];
