/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CommonModule, NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

export const SHARED_MODULES = [
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	RouterModule,
	CommonModule,
	NgxPermissionsModule,
	NgIf,
];
