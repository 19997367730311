import { createAction, props } from '@ngrx/store';
import { Config } from '@core/models/config.interface';

export const setConfig = createAction(
	'[Config] setConfig',
	props<{ config: Config }>()
);
export const setTheme = createAction(
	'[Config] setTheme',
	props<{ theme: string }>()
);
export const setOpened = createAction(
	'[Config] setOpened',
	props<{ opened: boolean }>()
);
export const setLang = createAction(
	'[Config] setLang',
	props<{ lang: string }>()
);

export const unSetConfig = createAction('[Auth] unSetConfig');
