import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EncryptionService {

	clavePredefinida: CryptoKey | undefined;
	clavePredefinidaImportada: Promise<void> | undefined;

	constructor() { 
	 	this.clavePredefinidaImportada = this.importarClavePredefinida(); // Llama a la función de importación en el constructor
	}

	getMessageEncoding() {
		let message = 'text';
		let enc = new TextEncoder();
		console.log(enc.encode(message));
		return enc.encode(message);
	}

	async importarClavePredefinida(): Promise<void> {
	 	const clavePredefinidaHex = '2b7e151628aed2a6abf7158809cf4f2c';
	 	const claveArrayBuffer = this.hexStringToArrayBuffer(clavePredefinidaHex);
	
	 	try {
	 		this.clavePredefinida = await window.crypto.subtle.importKey(
	 			'raw',
	 			claveArrayBuffer,
	 			{ name: 'AES-GCM' },
	 			true,
	 			['encrypt', 'decrypt']
	 		);
	 	} catch (error) {
	 		console.error('Error al importar la clave predefinida:', error);
	 	}
	}
  
	hexStringToArrayBuffer(hexString: string): ArrayBuffer {
	 	const bytes = new Uint8Array(hexString.match(/[\da-f]{2}/gi)!.map(h => parseInt(h, 16)));
	 	return bytes.buffer;
	}

	async encryptMessage(obj) {
		await this.clavePredefinidaImportada;

		let encoded = new TextEncoder().encode(JSON.stringify(obj));

		// The iv must never be reused with a given key.
		let iv = window.crypto.getRandomValues(new Uint8Array(16));
		let aad = window.crypto.getRandomValues(new Uint8Array(16));
		let ciphertext = await window.crypto.subtle.encrypt(
			{
			name: "AES-GCM",
			iv: iv,
			additionalData: aad,
			},
			this.clavePredefinida,
			encoded
		);
		
		const result = new Uint8Array(iv.length + ciphertext.byteLength + aad.length);   
		const cipherTextArray = new Uint8Array(ciphertext);
		const tag = cipherTextArray.slice(cipherTextArray.length - 16, cipherTextArray.length);
		const cipher = cipherTextArray.slice(0, cipherTextArray.length - 16);
		result.set(iv);   
		result.set(aad, iv.length);
		result.set(tag, aad.length + iv.length);
		result.set(cipher, aad.length + iv.length + tag.length);
		
		return btoa(String.fromCharCode(...result));
	}

}
