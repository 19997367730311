import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { analytics as analyticsData } from './data';
import { VassMockApiService } from '@core/lib/mock-api';

@Injectable({
	providedIn: 'root'
})
export class DashboardMockApi {
	private _analytics: any = analyticsData;

	constructor(private _vassMockApiService: VassMockApiService) {
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._vassMockApiService
			.onGet('api/dashboards')
			.reply(() => [200, cloneDeep(this._analytics)]);
	}
}
