export const ROUTES = {
	WELCOME: 'welcome',
	OTP: 'send-otp',
	VERIFY: 'verify-identity',
	VERIFIDATA: 'verification-data',
	EMPLOYMENT: 'employment-and-address',
	FINANCIAL: 'financial-data',
	CRYPTO_INFO: 'crypto-information',
	DOCUMENTS: 'loading-documents',
	TERMS: 'terms-conditions',
	CONTACT: 'costumer-contact',
	FINISHED: 'costumer-finished',
	DEBIT_CARD: 'debit-card',
	CONGRATS: 'congrats',
	DOWNLOAD_APP:'downloadApp',
	NOT_ALLOWED: 'not-allowed'
};
