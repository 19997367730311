import { ActionReducerMap } from '@ngrx/store';
import * as ui from './ui/ui.reducer';
import * as auth from './auth/auth.reducer';
import * as router from './router/router.reducer';
import * as config from './config/config.reducer';
export interface AppState {
	ui: ui.State;
	auth: auth.State;
	router: router.State;
	config: config.State;
}

/* Exporting the reducers. */
export const appReducers: ActionReducerMap<AppState, any> = {
	ui: ui.uiReducer,
	auth: auth.authReducer,
	router: router.routerReducer,
	config: config.configReducer
};
