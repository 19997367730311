import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { accesTokenInterface } from "@app/core/models/accesToken.interface";
import { apiUrls } from "@environments/api-url";
import { Observable, BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GetTokenInitService {
  private http = inject(HttpClient);
  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor() {}

  public getInitTransaction(): Observable<accesTokenInterface> {
    return this.http.get<accesTokenInterface>(apiUrls.accessToken).pipe(
      tap((accesToken) => {
        this.setAccesToken(accesToken["Access-Token"]);
      })
    );
  }

  private setAccesToken(accesToken: string): void {
    sessionStorage.setItem('publicToken', accesToken);
    this.accessTokenSubject.next(accesToken);
  }

  public getAccesToken(): Observable<string | null> {
    return this.accessTokenSubject.asObservable();
  }
}
