<div class="card flex justify-content-center">
	<p-dialog
		[visible]="true"
		[modal]="true"
		[style]="{ width: '50vw' }"
		[draggable]="false"
		[closable]="false"
		[resizable]="true"
		[breakpoints]="{ '960px': '80vw' }"
	>
		<div class="flex flex-column justify-content-center align-items-center">
			<img src="assets/icons/ic_error.svg" alt="icono error" /><span
				[innerHTML]="'MODAL_LABELS.HEADER' | translate"
			></span>
			<p class="my-4">
				{{ (message$ | async | translate) || message$ }}
			</p>
			<p *ngIf="error_code$ !== (undefined || null)" class="error_code">
				{{ error_code$ | translate }}
			</p>
			<button (click)="onContinueClicked()" class="btn btn--primary">
				{{ (buttonLabel | translate) || ('BUTTONS_LABELS.ACCEPT' | translate) }}
			</button>
		</div>
	</p-dialog>
</div>
