import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UtilsService } from "../services/utils.service";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { ROUTES } from "@app/pages/routes";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private utilService: UtilsService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let idClient: string = null;
    let reqClone = req;
    let token = sessionStorage.getItem("publicToken");
    let noToken = ["onboarding-web/security/auth"].some((urlPart) =>
      req.url.includes(urlPart)
    );
    let notoken2 = req.url.includes("/assets/");
    let isExcluded = req.url.includes("/security/appian-process") || req.url.includes("/security/onfido-process") || req.url.includes("/Client/onboarding") ||(req.method === "POST" && req.url.includes("/Client/profile"));
    let termsPath = [
      "privacy-policy",
      "terms-and-conditions",
      "privacy-policy",
      "escrow",
    ];
    let isTerms = termsPath.find((path) => req.url.includes(path));
    let nameClientId = !!isTerms ? "appianClientId" : "clientId";
    let isAppianProcess = req.url.includes("/security/appian-process");

    this.utilService.getIdClientObservable?.subscribe((resp) => {
      idClient = resp;
      if (
        !resp &&
        !window.location.href.includes("welcome") &&
        !window.location.href.includes("verification-data")
      ) {
        console.log("Redirect Rputer", this.router.url, window.location.href);
        this.router.navigateByUrl(
          `${ROUTES.WELCOME}?token=${environment.TOKEN_URL}`
        );
        return;
      }
    });

    reqClone = reqClone.clone({
      setHeaders: {
        "accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        ...(isAppianProcess ? { "channel": "ONBOARDING" } : {}),
        ...(noToken || notoken2 ? {} : { "Access-Token": token }),
        ...(!isExcluded && idClient ? { [nameClientId]: String(idClient) } : {}),
      },
    });

    return next.handle(reqClone);
  }
}
