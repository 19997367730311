import { createReducer, on } from '@ngrx/store';
import { Config } from '@core/models/config.interface';
import {
	setConfig,
	setLang,
	setOpened,
	setTheme,
	unSetConfig
} from './config.actions';
export interface State {
	config: Config;
}
export const initialState: State = {
	config: {
		theme: '',
		opened: false,
		lang: ''
	}
};

/* Creating a reducer function that will be used to update the state. */
const _configReducer = createReducer(
	initialState,
	on(setTheme, (state, { theme }) => ({
		...state,
		config: { ...state.config, theme }
	})),
	on(setOpened, (state, { opened }) => ({
		...state,
		config: { ...state.config, opened }
	})),
	on(setLang, (state, { lang }) => ({
		...state,
		config: { ...state.config, lang }
	})),
	on(unSetConfig, (state) => ({ ...state, user: initialState.config }))
);

export function configReducer(state: any, action: any) {
	return _configReducer(state, action);
}
